<template>
  <div class="contact-page">
    <h1 class="text-2xl border-b-2 border-gray-400 py-2 mx-2">Contact Me</h1>
    <photo :url="profileImage" :height="'100%'" :width="'100%'" />
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-4 py-4">
      <p>
        I'm currently working at Goldman Sachs in Bengaluru. I completed my
        undergraduate at Indian Institute of technology Indore.
      </p>
      <p>
        Connect with me on
        <a href="https://www.linkedin.com/in/krishanu-saini/" class="text-blue-400">LinkedIn</a>
        <br />
        Message me at
        <a href="mailto:krishanu21saini@gmail.com" class="text-blue-400">krishanu21saini@gmail.com</a>
      </p>
    </div>
  </div>
</template>

<script>
import Photo from '../components/Photo.vue'
import { profileImage } from '@/assets/skillsList'

export default {
  name: 'Contact',
  components: {
    Photo,
  },
  data() {
    return {
      profileImage: profileImage,
    }
  },
}
</script>

<style scoped>
.contact-page {
  text-align: center;
  padding: 20px;
}

.section-body {
  padding: 20px;
}

.profile-pic {
  width: auto;
  border-radius: 50%;
  margin-bottom: 20px;
}
</style>