var webdevSkills = [
  'react.png',
  'vue.png',
  'flutter.png',
  'graphql.png',
  'flask.png',
  'bootstrap.png',
]

var programmingSkills = [
  'c++.png',
  'c.png',
  'python.png',
  'linux.png',
  'go.png',
  'sql.png',
]

var otherSkills = [
  'docker.png',
  'postgres.png',
  'aws2.png',
  'azure.png',
  'github.png',
  'mongodb.png',
]

var publications = [
  'publications-1.jpg',
  'publications-2.jpg',
  'publications-3.jpg',
  'publications-4.jpg',
  'publications-5.jpg',
  'publications-6.jpg',
  'publications-7.jpg',
]

var projects = {
  orthodontics: 'project/teeth alignment - Made with Clipchamp.mp4',
  i2i: 'project/Image-to-Image.png',
  sketches: 'project/sketches.png',
  highway1: 'project/traffic1.png',
  highway2: 'project/traffic2.png',
}

var profileImage = 'krishanu_20240413.jpg'

module.exports = {
  webdevSkills: webdevSkills,
  programmingSkills: programmingSkills,
  otherSkills: otherSkills,
  profileImage: profileImage,
  publications: publications,
  projects: projects,
}
